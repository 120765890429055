import React, { Component, lazy} from "react";
import { Route, Switch} from "react-router-dom";
import http from "./services/httpServices";
import auth from "./services/authService";
import ScrollToTop from "react-scroll-to-top";

lazy(()=>import("bootstrap/dist/css/bootstrap.min.css"));
const NavbarComp = lazy(()=>import("./components/navBar"));
const Home = lazy(()=>import("./components/home"));
const About = lazy(()=>import("./components/about"));
const Properties = lazy(()=>import("./components/properties"));
const Contact = lazy(()=>import("./components/contact"));
const Footer = lazy(()=>import("./components/footer"));
const UploadImages = lazy(()=>import("./components/upload"));
const PostProperty = lazy(()=>import("components/postProperty"));
const PostBuilding = lazy(()=>import("components/postBuilding"));
const AddBuilders = lazy(()=>import("components/addBuilders"));

const PostProject = lazy(()=>import("components/postProject"));
const PostCoworking = lazy(()=>import("components/postCoworking"));
const PostUnit = lazy(()=>import("components/postUnits"));
const PostResidentialUnits =lazy(()=>import("components/postResidentialUnits"))
const Login = lazy(()=>import("./components/login"));
const Logout = lazy(()=>import("./components/logout"));
const ProtectedRoute = lazy(()=>import("./components/commons/protectedRoute"));
const Dashboard = lazy(()=>import("./components/dashboard"));
const NotFound = lazy(()=>import("./components/notfound"));
const Services = lazy(()=>import("./components/services"));
const Privacy = lazy(()=>import("./components/privacy"));
const OfficeSpaceRent = lazy(()=>import("./components/propertyTypeView/officeSpaceRent"));
const OfficeSpaceRentCity = lazy(()=>import("./components/propertyTypeView/officeSpaceRentCity"));
const OfficeSpaceRentLocality = lazy(()=>import("./components/propertyTypeView/officeSpaceRentLocality"))
const EditProperty = lazy(()=>import("./components/editProperty"));
const PrimaryImage = lazy(()=>import( "./components/editProperty/primaryImage"));
const ShowProp = React.lazy(()=>import("./components/showProp"));
const CoworkingSpace =lazy(()=>import("./components/propertyTypeView/coworkingSpace"));
const NewProject = lazy(()=>import("./components/propertyTypeView/newProject"));
const UploadFloorPlans = lazy(()=>import("./components/uploadFloorPlans"));



class App extends Component {
  state = {};
  async componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });  
  }
  
  getPages = async (apiEndpoint) => {
    try {
      let response = await http.get(apiEndpoint);
      let pages = this.state.pages;
      let allpages = response.data.push(pages[0]);
      console.log(allpages);
      this.setState({ pages: allpages });
    } catch (error) {}
  };

  render() {

    const { user } = this.state;

    return (
      <React.Fragment><React.Suspense fallback={<div>Loading...</div>}>
       <ScrollToTop smooth color="red"/>
        <NavbarComp user={user} />
        
        <div className="container-flex overflow-hidden"> 
          <Switch>
            {/* Primary Routes  */}
            <Route exact path="/" component={Home} />
            <Route exact path="/404" component={NotFound} />
            <Route path="/about" component={About} />
            <Route path="/services" component={Services} />
            <Route path="/contact" component={Contact} />
            <Route path="/properties/" component={Properties} />
            <Route exact path="/privacy" component={Privacy} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />


            {/* Post Properties Routes */}
            <ProtectedRoute path="/post-property" component={PostProperty} />
            <ProtectedRoute path="/post-building" component={PostBuilding} />
            <ProtectedRoute path="/post-project" component={PostProject} />
            <ProtectedRoute path="/post-coworking" component={PostCoworking} />
            <ProtectedRoute path="/post-unit" component={PostUnit} />
            <ProtectedRoute path="/upload-floor-plans" component={UploadFloorPlans} />
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/primary-image/:propertyId" component={PrimaryImage} />
            <ProtectedRoute path="/edit-property" component={EditProperty} />
            <ProtectedRoute path="/upload" component={UploadImages} />
            <ProtectedRoute exact path="/post-residential-units" component={PostResidentialUnits} />
            <ProtectedRoute exact path="/add-builders" component={AddBuilders} />



            


            <Route
              exact
              path="/office-space-rent"
              component={OfficeSpaceRent}
            />
            <Route
              exact
              path="/office-space-rent/:city/:locality"
              component={OfficeSpaceRentLocality}
            />
            
            <Route
              exact
              path="/coworking-space-rent/:city/:locality"
              component={OfficeSpaceRentLocality}
            />

            <Route
              exact
              path="/office-space-rent/:city"
              component={OfficeSpaceRentCity}
            />
            <Route
              exact
              path="/coworking-space-rent/:city"
              component={OfficeSpaceRentCity}
            />
            
            <Route
              exact
              path="/coworking-office-space"
              component={CoworkingSpace}
            />
            <Route exact path="/projects" component={NewProject} />  
            <Route
              exact
              path="/projects/:city"
              component={NewProject}
            />

          {/* this must to be at the end  */}
          <Route path="/:seoUrl" component={ShowProp} /> 

          </Switch>
        </div>
        <Footer /></React.Suspense>
      </React.Fragment>
    );
  }
}

export default App;