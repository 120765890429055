import React from "react";
import ReactDOM from "react-dom";
import "./assets/index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import logger from "./services/logService";
import ScrollToTop from "./utils/scrollToTop";


logger.init();
ReactDOM.render(
  <React.StrictMode>    
    <BrowserRouter>
     <ScrollToTop> 
       <App />
     </ScrollToTop>
    </BrowserRouter>     
  </React.StrictMode>,
  document.getElementById("root")
  
);
