import http from "./httpServices";
import { apiUrl } from "../config.json";
import jwtdecode from "jwt-decode";

const apiEndpoint = apiUrl + "/auth";
const TOKEN_KEY = "x-auth-token";
http.setJwt(getJwt());

export async function login(email, password) {
  //const response = await http.post(apiEndpoint, { email, password });

  try {
    const resonse = await http.post(apiEndpoint, { email, password });
    if (resonse.headers[TOKEN_KEY]) {
      const token = resonse.headers[TOKEN_KEY];
      localStorage.setItem(TOKEN_KEY, token);
    }
  } catch (ex) {
    return ex;
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem("x-auth-tocken", jwt);
}

export function logout() {
  localStorage.removeItem(TOKEN_KEY);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(TOKEN_KEY);
    return jwtdecode(jwt);
  } catch (error) {}
}
function getJwt() {
  return localStorage.getItem(TOKEN_KEY);
}

export default {
  login,
  logout,
  getCurrentUser,
  loginWithJwt,
  getJwt,
};
