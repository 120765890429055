import * as Sentry from "@sentry/browser";

function init() {
  Sentry.init({
    dsn:
      "https://5c84e40499314901b20ef27ba460faea@o402981.ingest.sentry.io/5264992",
      denyUrls: "localhost"
  });
}

function log(error) {
  Sentry.captureException(error);
}

export default {
  init,
  log,
};
